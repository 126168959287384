export const m = {
  header: {
    langure: '中文',
    buy: 'Buy',
    home: 'Home',
    d3: 'Depth Camera',
    sdk: 'Skeletal Tracking',
    view: 'Machine Vision',
    sell: 'Retail Store Solution',
    about: 'About'
  },
  keywords: {
    index:
      'depth camera, motion sense, motion sensing, motion tracking, machine vision, skeletal tracking'
  },
  metaDescriptions: {
    index: 'Motion sensing solution via depth camera',
    depthCamera: 'Depth camera solution',
    about: 'About YDSENS company',
    sdk: 'Skeletal tracking SDK',
    sell: 'Retail store solution via motion sensing',
    view: 'Machine vision solution via motion sensing'
  },
  footer: {
    p1: 'Beijing Yundong Motion Sensing Technology Co.,Ltd.',
    p2:
      'Address:Building 2,China Central Place,No.79 Jianguo Road,Chaoyang District,Beijing,China',
    p3: 'E-mail:',
    e: 'contact@ydsens.com',
    p4: '(reply in 24 hours)'
  },
  type1: {
    1: 'YDSENS RGB-D Depth Camera',
    2: 'Independent research and development, Millimeter precision, Provide 30 FPS VGA depth data and 30 FPS VGA color data',
    3: 'Supporting Multi-platforms (Windows/Android/Linux/Mac)'
  },
  d3: {
    p1: 'YDSENS RGB-D Depth Camera',
    p2:
      'Independent research and development, Millimeter precision, Provide 30 FPS VGA depth data and 30 FPS VGA color data',
    p3:
      'Supporting multi-depth cameras work together and multi-platforms (Windows/Android/Linux/Mac)'
  },
  sell: {
    icon10: {
      p1: 'Retail store solution',
      p2:
        'Our special skeletal tracking version provides fundamental data for user tracking',
      p3: 'and user behavior analysis to self-service retail store'
    },
    icon99: {
      p1: 'Face ID payment',
      p2:
        'Providing fundamental data for face recognition and payment to self-service retail store'
    },
    icon9: {
      p1: '3D Face recognition',
      p2: 'Providing total solution to self-service retail store',
      p3: ''
    },
    1: 'YDSENS3D深度传感器',
    2: '自主知识产权RGB-D深度传感器，毫米级精度，提供每秒30帧的深度数据及彩色数据',
    3: '支持多平台(Windows/Android/Linux/Mac)'
  },
  about: {
    p1: 'YDSENS',
    p2: '韵动体感科技',
    info: {
      1: 'We are an innovation company founded by passionate engineers',
      2: 'from TOP Fortune 500 global high-tech company and mainly focusing',
      3: 'on machine version and motion sensing areas. Our products include',
      4: 'RGB-D depth camera and skeletal tracking SDK which providing the',
      5: 'fundamental technology to the next generation of nature user interface. ',
      6: 'The quality and performance of our products put us in the leading',
      7: 'position in these areas in the world.'
    },
    wechat: 'Wechat'
  },
  sdk: {
    1: {
      p1: 'Skeletal Tracking SDK',
      p2: 'Real-time six users tracking',
      p3: 'High accuracy and stability',
      p4: 'Tracking and interacting solution',
      p5: 'to motion control games'
    },
    2: {
      p1: 'Multi-platforms,Multi-programing languages support',
      p2:
        'Supporting multi-platforms (Windows/Android/Linux/Mac),multi-programing ',
      p3:
        'interfaces (C++,Unity 3D,Java) and customizing SDK features according to different requirements'
    },
    3: {
      p1: 'Supporting different users',
      p2:
        'Tracking user of different age, height, gender, no user initial pose required before tracking'
    }
  },
  view: {
    icon12: {
      p1: 'Package volume measurement solution for handheld device',
      p2:
        'Embedded depth camera module and our patent algorithm providing volume measurement solution for handheld device'
    },
    icon11: {
      p1: 'Automatic package volume measurement solution',
      p2:
        'Real-time automatic volume measure solution can be integrated with conveyor belt and saving cost for large logistics company'
    },
    icon19: {
      p1: 'Automatically package sorting solution',
      p2: 'Saving cost and increasing efficiency to logistics company',
      p3: ''
    },
    1: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    2: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    3: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    4: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    }
  },
  icon3: {
    p1: 'Embedded Depth Camera Module',
    p2:
      'Providing face recognition and face ID payment solution to mobile phone and tablet'
  },
  icon4: {
    p1: 'AI Skeletal Tracking SDK',
    p2:
      'High accuracy and stability, Real-time six users tracking at the same time',
    p3: 'Supporting Multi-platforms (Windows/Android/Linux/Mac)'
  },
  icon5: {
    p1: 'Machine vision solution',
    p2:
      'Automatic package volume measurement and intelligence obstacle detection provide “eyes” to machines',
    p3: 'Saving cost for logistics company'
  },
  icon6: {
    p1: 'Retail store solution',
    p2:
      'Face ID payment solution and supermarket customer tracking and behavior ',
    p3: 'recognition provide support for self-service store'
  },
  icon7: {
    p1: 'Embedded version depth camera',
    p2:
      'Providing face recognition and face ID payment solution to mobile phone and tablet'
  },
  icon8: {
    p1: 'VR device integration',
    p2: 'Providing interaction solution to VR device'
  }
}
