const deviceInfo = (function () {
  var MethodLibrary = (function () {
    // 方法库
    return {
      /**
       * @function 监测DOM树是否加载完毕.
       * @param fn
       */
      getOS: function () {
        // 获取当前操作系统
        var os
        if (
          navigator.userAgent.indexOf('Android') > -1 ||
          navigator.userAgent.indexOf('Linux') > -1
        ) {
          os = 'Android'
        } else if (navigator.userAgent.indexOf('iPhone') > -1) {
          os = 'iOS'
        } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
          os = 'WP'
        } else {
          os = 'Others'
        }
        return os
      },
      getOSVersion: function () {
        // 获取操作系统版本
        var OSVision = '1.0'
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // Android
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
        if (isAndroid) {
          // OSVision = navigator.userAgent.split(';')[1].match(/\d+\.\d+/g)[0]
        }
        if (isIOS) {
          OSVision = navigator.userAgent
            .split(';')[1]
            .match(/(\d+)_(\d+)_?(\d+)?/)[0]
        }
        return OSVision
      },
      getOrientationStatu: function () {
        // // 获取横竖屏状态
        // var orientationStatu
        // if (
        //   window.screen.orientation.angle == 180 ||
        //   window.screen.orientation.angle == 0
        // ) {
        //   // 竖屏
        //   orientationStatu = '竖屏'
        // }
        // if (
        //   window.screen.orientation.angle == 90 ||
        //   window.screen.orientation.angle == -90
        // ) {
        //   // 横屏
        //   orientationStatu = '横屏'
        // }
        // return orientationStatu
        return null
      },
      getDeviceType: function () {
        // 获取设备类型
        var deviceType
        var sUserAgent = navigator.userAgent.toLowerCase()
        var bIsIpad = sUserAgent.match(/(ipad)/i) == 'ipad'
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
        var bIsMidp = sUserAgent.match(/midp/i) == 'midp'
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
        var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
        var bIsAndroid = sUserAgent.match(/android/i) == 'android'
        var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
        var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'

        if (
          !(
            bIsIpad ||
            bIsIphoneOs ||
            bIsMidp ||
            bIsUc7 ||
            bIsUc ||
            bIsAndroid ||
            bIsCE ||
            bIsWM
          )
        ) {
          deviceType = 'PC' // pc
        } else if (
          bIsIphoneOs ||
          bIsMidp ||
          bIsUc7 ||
          bIsUc ||
          bIsAndroid ||
          bIsCE ||
          bIsWM
        ) {
          deviceType = 'Phone' // phone
        } else if (bIsIpad) {
          deviceType = 'ipad' // ipad
        } else {
          deviceType = '未知'
        }
        return deviceType
      },
      getNetWork: function () {
        // var netWork
        // switch (navigator.connection.effectiveType) {
        //   case 'wifi':
        //     netWork = 'wifi' // wifi
        //     break
        //   case '4g':
        //     netWork = '4G' // 4g
        //     break
        //   case '2g':
        //     netWork = '2G' // 2g
        //     break
        //   case '3g':
        //     netWork = '3G' // 3g
        //     break
        //   case 'ethernet':
        //     netWork = '以太网' // ethernet
        //     break
        //   case 'default':
        //     netWork = '未知' // 未知
        //     break
        // }
        return null
      },
      createFingerprint: function (domain) {
        // 生成浏览器指纹
        var fingerprint

        function bin2hex (s) {
          var i,
            l,
            n,
            o = ''
          s += ''
          for (i = 0, l = s.length; i < l; i++) {
            n = s.charCodeAt(i).toString(16)
            o += n.length < 2 ? '0' + n : n
          }
          return o
        }

        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        var txt = domain || window.location.host
        ctx.textBaseline = 'top'
        ctx.font = "14px 'Arial'"
        ctx.textBaseline = 'tencent'
        ctx.fillStyle = '#f60'
        ctx.fillRect(125, 1, 62, 20)
        ctx.fillStyle = '#069'
        ctx.fillText(txt, 2, 15)
        ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
        ctx.fillText(txt, 4, 17)
        var b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
        var bin = atob(b64)
        var crc = bin2hex(bin.slice(-16, -12))
        fingerprint = crc
        return fingerprint
      }
    }
  })()
  var LogicLibrary = (function () {
    // 逻辑层
    return {
      DeviceInfoObj: function (params) {
        var info = {
          deviceType: MethodLibrary.getDeviceType(), // 设备类型
          OS: MethodLibrary.getOS(), // 操作系统
          OSVersion: MethodLibrary.getOSVersion(), // 操作系统版本
          userAgent: navigator.userAgent, // 包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
          appVersion: navigator.appVersion, // 浏览器的版本号
          screenHeight: window.screen.height, // 屏幕高
          screenWidth: window.screen.width, // 屏幕宽
          language: navigator.language, // 当前使用的语言-国家
          // netWork: MethodLibrary.getNetWork(), // 联网类型
          fingerprint: MethodLibrary.createFingerprint(params.domain), // 浏览器指纹
          // orientation: MethodLibrary.getOrientationStatu() // 横竖屏
        }
        if (!params.info || params.info.length == 0) {
          return info
        } else {
          var infoTemp = {}
          for (var i in info) {
            params.info.forEach(function (item) {
              if (item.toLowerCase() == i.toLowerCase()) {
                item = i
                infoTemp[item] = info[item]
              }
            })
          }
          return infoTemp
        }
      }
    }
  })()
  return {
    getDeviceInfo: function (params) {
      return LogicLibrary.DeviceInfoObj(params)
    }
  }
})()
export default deviceInfo
const deviceType = deviceInfo.getDeviceInfo({ info: ['deviceType'] }).deviceType
if (deviceType === 'Phone') {
  ;(function (window, document) {
    'use strict'

    // 给hotcss开辟个命名空间，别问我为什么，我要给你准备你会用到的方法，免得用到的时候还要自己写。
    var hotcss = {}

    ;(function () {
      // 根据devicePixelRatio自定计算scale
      // 可以有效解决移动端1px这个世纪难题。
      var viewportEl = document.querySelector('meta[name="viewport"]'),
        hotcssEl = document.querySelector('meta[name="hotcss"]'),
        dpr = window.devicePixelRatio || 1,
        maxWidth = 540,
        designWidth = 0

      dpr = dpr >= 3 ? 3 : dpr >= 2 ? 2 : 1

      // 允许通过自定义name为hotcss的meta头，通过initial-dpr来强制定义页面缩放
      if (hotcssEl) {
        var hotcssCon = hotcssEl.getAttribute('content')
        if (hotcssCon) {
          var initialDprMatch = hotcssCon.match(/initial\-dpr=([\d\.]+)/)
          if (initialDprMatch) {
            dpr = parseFloat(initialDprMatch[1])
          }
          var maxWidthMatch = hotcssCon.match(/max\-width=([\d\.]+)/)
          if (maxWidthMatch) {
            maxWidth = parseFloat(maxWidthMatch[1])
          }
          var designWidthMatch = hotcssCon.match(/design\-width=([\d\.]+)/)
          if (designWidthMatch) {
            designWidth = parseFloat(designWidthMatch[1])
          }
        }
      }

      document.documentElement.setAttribute('data-dpr', dpr)
      hotcss.dpr = dpr

      document.documentElement.setAttribute('max-width', maxWidth)
      hotcss.maxWidth = maxWidth

      if (designWidth) {
        document.documentElement.setAttribute('design-width', designWidth)
      }
      hotcss.designWidth = designWidth // 保证px2rem 和 rem2px 不传第二个参数时, 获取hotcss.designWidth是undefined导致的NaN

      var scale = 1 / dpr,
        content =
          'width=device-width, initial-scale=' +
          scale +
          ', minimum-scale=' +
          scale +
          ', maximum-scale=' +
          scale +
          ', user-scalable=no'

      if (viewportEl) {
        viewportEl.setAttribute('content', content)
      } else {
        viewportEl = document.createElement('meta')
        viewportEl.setAttribute('name', 'viewport')
        viewportEl.setAttribute('content', content)
        document.head.appendChild(viewportEl)
      }
    })()

    hotcss.px2rem = function (px, designWidth) {
      // 预判你将会在JS中用到尺寸，特提供一个方法助你在JS中将px转为rem。就是这么贴心。
      if (!designWidth) {
        // 如果你在JS中大量用到此方法，建议直接定义 hotcss.designWidth 来定义设计图尺寸;
        // 否则可以在第二个参数告诉我你的设计图是多大。
        designWidth = parseInt(hotcss.designWidth, 10)
      }

      return (parseInt(px, 10) * 320) / designWidth / 20
    }

    hotcss.rem2px = function (rem, designWidth) {
      // 新增一个rem2px的方法。用法和px2rem一致。
      if (!designWidth) {
        designWidth = parseInt(hotcss.designWidth, 10)
      }
      // rem可能为小数，这里不再做处理了
      return (rem * 20 * designWidth) / 320
    }

    hotcss.mresize = function () {
      // 对，这个就是核心方法了，给HTML设置font-size。
      var innerWidth =
        document.documentElement.getBoundingClientRect().width ||
        window.innerWidth

      if (hotcss.maxWidth && innerWidth / hotcss.dpr > hotcss.maxWidth) {
        innerWidth = hotcss.maxWidth * hotcss.dpr
      }

      if (!innerWidth) {
        return false
      }

      document.documentElement.style.fontSize = (innerWidth * 20) / 320 + 'px'

      hotcss.callback && hotcss.callback()
    }

    hotcss.mresize()
    // 直接调用一次

    window.addEventListener(
      'resize',
      function () {
        clearTimeout(hotcss.tid)
        hotcss.tid = setTimeout(hotcss.mresize, 33)
      },
      false
    )
    // 绑定resize的时候调用

    window.addEventListener('load', hotcss.mresize, false)
    // 防止不明原因的bug。load之后再调用一次。

    setTimeout(function () {
      hotcss.mresize()
      // 防止某些机型怪异现象，异步再调用一次
    }, 333)

    window.hotcss = hotcss
    // 命名空间暴露给你，控制权交给你，想怎么调怎么调。
  })(window, document)
}
