import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import App from '@/App.vue'

Vue.use(Router)

const knownPaths = ['depthcamera', 'retail', 'machinevision', 'about', 'sdk']

const changeLocaleWhenNecessary = (lang) => {
  if (i18n.availableLocales.includes(lang) && store.state.locale !== lang) {
    store.dispatch('changeLocale', lang)
    return true
  }

  return false
}

const isPath = (matchedString) => {
  if (matchedString && matchedString.toLowerCase) {
    const path = matchedString.toLowerCase()
    return knownPaths.includes(path)
  }

  return false
}

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'root',
      beforeEnter(to, from, next) {
        next(store.state.locale)
      }
    },
    {
      path: '/:lang',
      component: App,
      beforeEnter(to, from, next) {
        const lang = to.params.lang
        if (isPath(lang)) {
          return next('/' + store.state.locale + '/' + lang)
        }

        if (changeLocaleWhenNecessary(lang)) {
          return next('/')
        }

        return next()
      },
      children: [
        {
          path: '',
          name: 'index',
          component: () => import('@/views/Index.vue'),
          meta: {
            title: 'home'
          }
        },
        {
          path: 'depthcamera',
          name: '3d',
          component: () => import('@/views/3d.vue'),
          meta: {
            title: 'd3'
          }
        },
        {
          path: 'retail',
          name: 'sell',
          component: () => import('@/views/sell.vue'),
          meta: {
            title: 'sell'
          }
        },
        {
          path: 'machinevision',
          name: 'view',
          component: () => import('@/views/view.vue'),
          meta: {
            title: 'view'
          }
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/views/about.vue'),
          meta: {
            title: 'about'
          }
        },
        {
          path: 'sdk',
          name: 'sdk',
          component: () => import('@/views/sdk.vue'),
          meta: {
            title: 'sdk'
          }
        }
      ]
    }
  ]
})
