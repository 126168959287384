import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'jquery'
import deviceInfo from './utils/browser'
import 'normalize.css/normalize.css'
import './utils/flexible'
import '@/assets/scss/app.scss'
import store from './store'
import i18n, { getMessages } from './plugins/i18n'

Vue.config.productionTip = false
const deviceType = deviceInfo.getDeviceInfo({ info: ['deviceType'] }).deviceType

router.beforeEach((to, from, next) => {
  const messages = getMessages()
  if (to.meta.title) {
    if (to.meta.title !== 'home') {
      document.title =
        messages.m.header[to.meta.title] + '-' + messages.m.footer.p1
    } else {
      document.title = messages.m.footer.p1
    }
  }
  next()
})

$('html').addClass(deviceType)
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
