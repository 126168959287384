import VueI18n from 'vue-i18n'
import Vue from 'vue'
import store from '../store'

Vue.use(VueI18n)
const getDefaultLocale = () => {
  if (store.state.locale) {
    return store.state.locale
  }

  let locale = 'en'
  var language =
    navigator &&
    ((navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.browserLanguage ||
      navigator.userLanguage)
  if (language && language.indexOf) {
    if (language.indexOf('zh-') === 0 || language === 'zh') {
      locale = 'zh-CN'
    } else if (language.toLowerCase() === 'en-us') {
      locale = 'en-US'
    }
  }

  store.dispatch('setBrowserDefaultLanguage', locale)
  store.dispatch('changeLocale', locale)
  return locale
}

const i18n = new VueI18n({
  locale: getDefaultLocale(),
  messages: {
    'zh-CN': require('../common/lang/zh'),
    'en-US': require('../common/lang/en'),
    en: require('../common/lang/en')
  }
})

export default i18n

export const getMessages = () => {
  const locale = store.state.locale
  return i18n.messages[locale]
}
