import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    locale: undefined,
    browserDefaultLanguage: undefined
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale
    },
    updateBrowserDefaultLanguage(state, lang) {
      state.browserDefaultLanguage = lang
    }
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      commit('updateLocale', newLocale)
    },
    setBrowserDefaultLanguage({ commit }, lang) {
      commit('updateBrowserDefaultLanguage', lang)
    }
  },
  plugins: [createPersistedState()]
})
