;(function (win, lib) {
  function init() {
    const doc = win.document
    const docEl = doc.documentElement
    const whdef = 100 / 1495
    const wH = window.innerHeight
    let wW = window.innerWidth
    wW = wW < 1284 ? 1284 : wW
    const rem = wW * whdef
    docEl.style.fontSize = rem + 'px'
  }
  init()
})(window, window['lib'] || (window['lib'] = {}))
