export const m = {
  header: {
    langure: 'English',
    buy: '购买',
    home: '首页',
    d3: '3D深度传感器',
    sdk: 'AI骨骼识别SDK',
    view: '机器视觉解决方案',
    sell: '新零售解决方案',
    about: '关于韵动'
  },
  keywords: {
    index: '深度相机, 运动感知, 运动追踪, 机器视觉, 骨骼追踪'
  },
  metaDescriptions: {
    index: '运用深度相机的运动感知解决方案',
    depthCamera: '深度相机解决方案',
    about: '关于YDSENS公司',
    sdk: '骨骼追踪SDK',
    sell: '运动感知零售商店解决方案',
    view: '运用运动感知的机器视觉解决方案'
  },
  footer: {
    p1: '北京韵动体感科技有限公司',
    p2: '北京市朝阳区建国路华贸中心2号楼',
    p3: '邮箱：',
    e: 'contact@ydsens.com',
    p4: '(24小时之内回复)'
  },
  type1: {
    1: 'YDSENS 3D深度传感器',
    2: '自主知识产权RGB-D深度传感器，毫米级精度，提供每秒30帧的深度数据及彩色数据',
    3: '支持多平台(Windows/Android/Linux/Mac)'
  },
  d3: {
    p1: 'YDSENS 3D深度传感器',
    p2:
      '毫米级精度，稳定性更强，提供每秒30帧的深度数据及彩色数据，多设备同时运行不受影响',
    p3: '支持多平台(Windows/Android/Linux/Mac)'
  },
  sell: {
    icon10: {
      p1: '行为动作识别分析',
      p2: '独有的无人零售视觉跟踪及动作识别方案',
      p3: '为用户行为分析及完全实现无人购物提供终极解决方案'
    },
    icon99: {
      p1: '3D人脸识别',
      p2: '3D人脸识别更快速、精准，误差率低，安全性高'
    },
    icon9: {
      p1: '3D人脸识别',
      p2: '提供一站式无人零售整体解决方案',
      p3: ''
    },
    1: 'YDSENS3D深度传感器',
    2: '自主知识产权RGB-D深度传感器，毫米级精度，提供每秒30帧的深度数据及彩色数据',
    3: '支持多平台(Windows/Android/Linux/Mac)'
  },
  about: {
    p1: 'YDSENS',
    p2: '韵动体感科技',
    info: {
      1: '我们是致力于体感交互技术的AI视觉感知创新公司，',
      2: '由来自于全球顶级外企核心研发团队成员联合创办。',
      3: '自主研发行业领先的3D传感器和体感识别算法技术，',
      4: '打造世界级的体感应用平台及整体解决方案，',
      5: '为未来的人机交互及机器视觉提供技术基础。',
      6: '',
      7: ''
    },
    wechat: '微信公众号'
  },
  sdk: {
    1: {
      p1: '韵动AI骨骼识别SDK',
      p2: '支持实时多达六人的同时识别',
      p3: '准确率高，稳定性强',
      p4: '为体感游戏及交互提供完整解决方案',
      p5: ''
    },
    2: {
      p1: '多平台，多接口支持',
      p2: '支持多平台 (Windows/Android/Linux/Mac)',
      p3: '提供多种开发语言接口类型(C++,Unity 3D,Java)，支持方案定制服务'
    },
    3: {
      p1: '支持不同年龄、身高及性别',
      p2: '即时识别，无需初始姿态，支持不同身高、年龄及性别'
    }
  },
  view: {
    icon12: {
      p1: '手持终端体积测量',
      p2: '小型化深度传感器及专利测量算法为移动终端设备包裹体积检测提供基础'
    },
    icon11: {
      p1: '自动化体积测量',
      p2: '实时货物包裹体积测量及独有的动态物体测量方案为智慧物流提供基础'
    },
    icon19: {
      p1: '机器视觉解决方案',
      p2: '3D视觉为机器装上一双智慧之眼，实现全自动智能分拣、搬运',
      p3: '让企业更高效、低成本、安全可控'
    },
    1: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    2: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    3: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    },
    4: {
      t1: '骨骼识别sdk',
      t2: '支持不同年龄，身高及性别'
    }
  },
  icon3: {
    p1: '小型化的3D深度传感器',
    p2: '为手机和平板等移动终端设备人脸识别及支付提供支持'
  },
  icon4: {
    p1: '韵动AI骨骼识别SDK',
    p2: '识别准备率高，稳定性强，实时多达六人同时识别',
    p3: '支持多平台 (Windows/Android/Linux/Mac)'
  },
  icon5: {
    p1: '机器视觉解决方案',
    p2: '自动化体积测量和智能导航避障系统为机器装上一双智慧之眼',
    p3: '让物流更高效并节约成本'
  },
  icon6: {
    p1: '新零售解决方案',
    p2: '人脸识别支付系统和特有的超市行为识别分析系统',
    p3: '实现全自助无人新零售'
  },
  icon7: {
    p1: '小型化的3D深度传感器',
    p2: '为移动端设备人脸识别及支付提供完美解决方案'
  },
  icon8: {
    p1: '与VR设备集成',
    p2: '为VR提供感知世界的窗口及与用户交互的渠道'
  }
}
